@keyframes scrolls {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.slideTrack {
  animation: scrolls var(--animation-duration) linear infinite;

  @media (-webkit-animation) {
    webkit-animation: scrolls var(--animation-duration) linear infinite;
  }
}

@keyframes second-scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.secondSlideTrack {
  animation: second-scroll var(--animation-duration) linear infinite;

  @media (-webkit-animation) {
    webkit-animation: second-scroll var(--animation-duration) linear infinite;
  }
}
