@font-face {
  font-family: 'Fallback';
  src: local(Arial);
  size-adjust: 109%;
  ascent-override: 111%;
  descent-override: 38%;
  line-gap-override: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Fallback';
  src: local('Arial');
  size-adjust: 108%;
  ascent-override: 85%;
  descent-override: 18%;
  line-gap-override: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Fallback';
  src: local('Arial Black');
  size-adjust: 92%;
  ascent-override: 136%;
  descent-override: 53%;
  line-gap-override: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Fallback';
  src: local('Arial Black');
  size-adjust: 88%;
  ascent-override: 135%;
  descent-override: 59%;
  line-gap-override: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'GalanoGrotesque';
  src: url('/assets/fonts/GalanoGrotesque-Light.eot');
  src:
    url('/assets/fonts/GalanoGrotesque-Light.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/GalanoGrotesque-Light.woff2') format('woff2'),
    url('/assets/fonts/GalanoGrotesque-Light.woff') format('woff'),
    url('/assets/fonts/GalanoGrotesque-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GalanoGrotesque';
  src: url('/assets/fonts/GalanoGrotesque-Regular.eot');
  src:
    url('/assets/fonts/GalanoGrotesque-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/GalanoGrotesque-Regular.woff2') format('woff2'),
    url('/assets/fonts/GalanoGrotesque-Regular.woff') format('woff'),
    url('/assets/fonts/GalanoGrotesque-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GalanoGrotesque';
  src: url('/assets/fonts/GalanoGrotesque-MediumItalic.eot');
  src:
    url('/assets/fonts/GalanoGrotesque-MediumItalic.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/GalanoGrotesque-MediumItalic.woff2') format('woff2'),
    url('/assets/fonts/GalanoGrotesque-MediumItalic.woff') format('woff'),
    url('/assets/fonts/GalanoGrotesque-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'GalanoGrotesque';
  src: url('/assets/fonts/GalanoGrotesque-Medium.eot');
  src:
    url('/assets/fonts/GalanoGrotesque-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/GalanoGrotesque-Medium.woff2') format('woff2'),
    url('/assets/fonts/GalanoGrotesque-Medium.woff') format('woff'),
    url('/assets/fonts/GalanoGrotesque-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GalanoGrotesque';
  src: url('/assets/fonts/GalanoGrotesque-SemiBold.eot');
  src:
    url('/assets/fonts/GalanoGrotesque-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/GalanoGrotesque-SemiBold.woff2') format('woff2'),
    url('/assets/fonts/GalanoGrotesque-SemiBold.woff') format('woff'),
    url('/assets/fonts/GalanoGrotesque-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GalanoGrotesque';
  src: url('/assets/fonts/GalanoGrotesque-Bold.eot');
  src:
    url('/assets/fonts/GalanoGrotesque-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/GalanoGrotesque-Bold.woff2') format('woff2'),
    url('/assets/fonts/GalanoGrotesque-Bold.woff') format('woff'),
    url('/assets/fonts/GalanoGrotesque-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
